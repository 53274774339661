import React, {Component} from "react"

import Cookie from './cookie'
import CookieFake from './cookie-fake'
import Toggler from '../toggler'

import {Cookies} from 'react-cookie'
import OnClickActiveArea from "../react/on-click-active-area";

const cookies = new Cookies();

class CookieBar extends Component {

    constructor(props) {
        super(props);

        //set Cookie false if not checked
        this.state = {
            showCookieBar: cookies.get('psd-cookiebar-accepted') === 'undefined' || !cookies.get('psd-cookiebar-accepted')
        };

    }

    hideCookieBar = () => {
        this.setState({showCookieBar: false});
    }

    showCookieBar = () => {
        this.setState({showCookieBar: true});
    }

    setCookiesAccepted = () => {
        cookies.set('psd-cookiebar-accepted', true, {'path': '/', 'domain': this.props.domain, 'maxAge': 8640000});
        this.hideCookieBar();
    }

    //re-open cookie bar
    removeCookiesAccepted = () => {
        cookies.remove('psd-cookiebar-accepted', {'path': '/', 'domain': this.props.domain});
        this.showCookieBar();
    }

    reload = () => {
        window.location.reload();
    }

    checkAllAndReload = (cookies) => {
        cookies.forEach(function(cookie) {
            if (cookie) {
                cookie.setCheckedTrue();
                cookie.addActivationCookie();
            }
        });
        this.setCookiesAccepted();
        this.reload();
    }

    uncheckAllAndReload = (cookies) => {
        cookies.forEach(function(cookie) {
            if (cookie) {
                cookie.setCheckedFalse();
                cookie.removeAllCookies();
            }
        });
        this.setCookiesAccepted();
        this.reload();
    }

    saveAll = (cookies) => {

        //iterate through each cookie and handle clicked checkbox

        cookies.forEach(function(cookie) {
            if (cookie) {
                cookie.handleCookies();
            }
        });
        this.setCookiesAccepted();
        this.reload();
    }

    render() {
        this.cookies = [];
        const showCookieBar = this.state.showCookieBar;
        let cookieBar;

        if (showCookieBar) {
            cookieBar = <div className="cookie-bar">
                <p>
                    Wir speichern und verarbeiten Ihre personenbezogenen Informationen für folgende Zwecke: <strong>Cookies, die zur Funktionalität der Seite benötigt werden, Besucher-Statistiken.</strong> Lesen Sie dazu auch unsere <OnClickActiveArea href="datenschutz">Datenschutzbestimmung</OnClickActiveArea>.
                </p>

                <button className="cookie-bar-accept-button" onClick={() => this.checkAllAndReload(this.cookies)}>Alle akzeptieren</button>
                <button className="cookie-bar-decline-button" onClick={() => this.uncheckAllAndReload(this.cookies)}>Alle ablehnen</button>

                <div className="cookie-bar-detail">
                    <Toggler title="Cookie-Details anzeigen" uniqueId="c01">
                        <br className="clear" />
                        <CookieFake name="Cookie Bar Cookie (notwendig)" description="Überprüfen, ob es bereits eine Interaktion mit der Cookie Bar gab, damit diese beim Seitenladen nicht mehr angezeigt wird." />
                        <Cookie ref={(cookie) => { this.cookies.push(cookie) }} name="Google Analytics" description="Sammeln von Besucher-Statistiken." activationCookie="google-analytics-gdpr" onRemoveCookies={['_ga', '_gid', '_gat', 'gatsby-plugin-google-analytics-gdpr_cookies-enabled']} domain='.fsproduktion.de' />

                        <button className="cookie-bar-save-button" onClick={() => this.saveAll(this.cookies)}>Speichern</button>
                    </Toggler>
                </div>

            </div>;
        }
        else {
            cookieBar = '';
        }

        return (
            <div className="cookie-bar-wrapper">
                <button className="cookie-bar-trigger" onClick={() => this.removeCookiesAccepted()}>
                    cookies
                </button>
                {cookieBar}
            </div>
        );
    }
}

export default CookieBar