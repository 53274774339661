/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Menu from './menu'
import Footer from './footer'
import { Helmet } from "react-helmet"

import FixedMenu from './react/fixed-menu'
import Datenschutz from '../pages/datenschutz'
import Impressum from '../pages/impressum'
import OnClickRemoveActiveAreas from './react/on-click-remove-active-areas'

const Layout = ({ children }) => {

  return (
    <>
        <Helmet htmlAttributes={{ lang : 'de' }}/>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        </Helmet>
        <div>
            <OnClickRemoveActiveAreas>
                <FixedMenu>
                    <Menu />
                </FixedMenu>

                    <main>{children}</main>

                <Footer />
            </OnClickRemoveActiveAreas>

            <Datenschutz />
            <Impressum />
        </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
