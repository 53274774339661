import React from 'react'
import OnClickRemoveActiveAreas from "../components/react/on-click-remove-active-areas";

const Impressum = () => (
    <div id="impressum" className="outside-area">
        <div className="container">
            <div className="close-button">
                <OnClickRemoveActiveAreas>Schließen &nbsp;&times;</OnClickRemoveActiveAreas>
            </div>
            <h2 className="main-headline">Impressum</h2>

            <p><strong>Finzel + Schuck</strong> Produktions GmbH & Co.KG<br />
                Paul-Seydel-Str. 13<br />
                09212 Limbach-Oberfrohna
            </p>

            <p>Tel <a href="tel:+49372260020">+49 3722 60 02-0</a><br />
                Fax +49 3722 60 02-49<br />
                <a href="mailto:info@fsproduktion.de">info@fsproduktion.de</a><br />
                www.fsproduktion.de
            </p>

            <p>
                Geschäftsführer: Henry Schuck<br />
                Registergericht: AG Chemnitz HRA 942<br />
                USt.-ID: DE 151830891<br />
                Steuernr.: 221/153/13901
            </p>

            <p>
                Persönlich haftende Gesellschafterin:<br />
                Finzel + Schuck Geschäftsführungsgesellschaft mbH – Sitz: Limbach-Oberfrohna<br />
                Registergericht: AG Chemnitz HRB 6046<br />
                Geschäftsführer: Henry Schuck, Alexander Schuck
            </p>

            <h2>Haftung für Inhalte</h2>
            <p>Obwohl wir uns um Aktualität, Vollständigkeit und Richtigkeit der Inhalte unserer Seiten bemühen, können wir
                hierfür keine Garantie übernehmen. Nach § 7 Absatz 1 TDG sind wir für eigene Inhalte auf unseren Seiten nach
                den allgemeinen Gesetzen verantwortlich. Eine Verpflichtung zur Überwachung<br />
                übermittelter oder gespeicherter fremder Informationen besteht jedoch nicht (§§8-10 TDG).</p>

            <h2>Haftung für Links</h2>
            <p>Diese Seite enthält Links auf externe Webseiten Dritter. Auf die Inhalte dieser verlinkten Webseiten haben
                wir keinen Einfluss. Für die Richtigkeit der Inhalte ist immer der jeweilige Anbieter oder Betreiber
                verantwortlich, weshalb wir diesbezüglich keinerlei Gewähr übernehmen.</p>

            <h2>Urheberrecht</h2>
            <p>Die durch den Betreiber und Inhaber dieser Seite erstellten Inhalte und Werke auf diesen Webseiten
                unterliegen dem deutschen Urheberrecht.</p>

            <h2>Rechtswirksamkeit</h2>
            <p>Sollten einzelne Regelungen oder Formulierungen dieses Haftungsausschlusses unwirksam sein oder werden,
                bleiben die übrigen Regelungen in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.</p>
            <br />
            <br />
        </div>

    </div>
)

export default Impressum