import React from "react"

import ImageTriple from './images/image-triple'
import Logo from './images/logo'
import ListLink from "./list-link";
import OnClickActiveArea from './react/on-click-active-area'
import CookieBar from "./cookies/cookie-bar";

const Footer = () => (
    <footer>
        <ImageTriple src="footer/footer1.jpg" title="Footer Bild 1" className="col-3" />
        <ImageTriple src="footer/footer2.jpg" title="Footer Bild 2" className="col-3" />
        <ImageTriple src="footer/footer3.jpg" title="Footer Bild 3" className="col-3" />
        <div className="footer-content">
            <div className='footer-logo-wrapper'>
                <Logo title="Finzel & Schuck Produktions GmbH & Co. KG" />
            </div>
            <div className="container footer-content-inner">
                <div className="col-2 col-mls-1">
                    <div className="footer-content-inner-left">
                        <p>
                            Finzel + Schuck<br />
                            Produktions Gmbh &amp; Co. KG<br />
                            Paul-Seydel-Strasse 13<br />
                            09212 Limbach-Oberfrohna
                        </p>
                        <p>
                            Tel <a href="tel:+493722600240">+49 3722 60 02-40</a><br />
                            Fax +49 3722 60 02-49<br />
                            <a href="mailto:info@finzelundschuck.de">info@finzelundschuck.de</a>
                        </p>
                    </div>
                </div>
                <div className="col-2 col-mls-1">
                    <div className="footer-content-inner-right">
                        <ul className='footer-navigation'>
                            <ListLink to='#ueberuns'>Über uns</ListLink>
                            <ListLink to='#stickerei'>Stickerei</ListLink>
                            <ListLink to='#textildruck'>Textildruck</ListLink>
                            <ListLink to='#konfektion'>Konfektion</ListLink>
                            <ListLink to='#referenzen'>Referenzen</ListLink>
                            <ListLink to='#ansprechpartner'>Ansprechpartner</ListLink>
                            <li><OnClickActiveArea href="datenschutz">Datenschutz</OnClickActiveArea></li>
                            <li><OnClickActiveArea href="impressum">Impressum</OnClickActiveArea></li>
                            <li><CookieBar domain=".fsproduktion.de"/></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer