import React, { Component } from 'react'

class FixedMenu extends Component {

    constructor(props) {
        super(props)
        this.state ={
           activeClass: 'static'
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            const menuHeight = document.getElementsByClassName('menu')[0].clientHeight;
            if (window.pageYOffset > menuHeight)
            {
                this.setState({activeClass: 'fixed'});
            }
            else {
                this.setState({activeClass: 'static'});
            }
        });
    }

    render() {
        return <div className={this.state.activeClass}>
            {this.props.children}
        </div>;
    }
}

export default FixedMenu

