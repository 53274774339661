import React, { Component } from 'react'
import {gaPageView} from "../helpers";

class OnClickActiveArea extends Component {
    constructor(props) {
        super(props);
        this.addClass = this.addClass.bind(this);
    }

    addClass(e) {
        e.preventDefault();
        e.stopPropagation();

        var activeAreas = document.getElementsByClassName('activeArea');
        for (var i = 0; i<activeAreas.length; i++) {
            activeAreas[i].classList.remove('activeArea')
        }

        var currentLink = this.props.href;

        var currentElement = document.getElementById(currentLink);

        if (currentElement) {
            currentElement.classList.toggle('activeArea');
        }

        window.history.pushState('', this.props.children, '#' + this.props.href);
        gaPageView();
    };

    render() {
        return (
                <a
                    onClick={this.addClass}
                    href={this.props.href}
                >
                    {this.props.children}
                </a>
            );

    }
}

export default OnClickActiveArea
