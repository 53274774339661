import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";

import ReactGA from 'react-ga';
import {Cookies} from 'react-cookie'

const cookies = new Cookies();

//add PageView for Google Analytics because no Reload / RouteUpdate Trigger

function gaPageView() {
    let useGa = !!(cookies.get('google-analytics-gdpr') || cookies.get('google-analytics-gdpr') === 'undefined');
    if (useGa)
    {
        ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash);
    }
}

const ListLink = props => (
    <li><AnchorLink onAnchorLinkClick={() => gaPageView()} activeClassName="active" to={'/' + props.to}>{props.children}</AnchorLink></li>
)

export default ListLink