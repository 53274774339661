import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Logo from './images/logo'

import ListLink from './list-link'
import MobileMenu from './mobile-menu'
import {gaPageView} from "./helpers";

const Menu = () => (
    <div id='menu' className='menu'>
        <div className='container'>
            <div className='logo--wrapper'>
                <AnchorLink onAnchorLinkClick={() => gaPageView()} to="/#home">
                    <Logo title="Finzel & Schuck Produktions GmbH & Co. KG" />
                </AnchorLink>
            </div>
            <ul className='navigation'>
                <ListLink to='#ueberuns'>Über uns</ListLink>
                <ListLink to='#stickerei'>Stickerei</ListLink>
                <ListLink to='#textildruck'>Textildruck</ListLink>
                <ListLink to='#konfektion'>Konfektion</ListLink>
                <ListLink to='#referenzen'>Referenzen</ListLink>
                <ListLink to='#ansprechpartner'>Ansprechpartner</ListLink>
            </ul>
        </div>
        <MobileMenu />
    </div>
)

export default Menu