import React from 'react'

const CookieFake = (props) => (
    <div className="cookie-item">
        <label className="cookie-bar-switch">
            <input className="cookie-bar-input" type="checkbox" disabled checked/>
            <span className="cookie-bar-slider"> </span>
        </label>
        <span className="cookie-name">{props.name}</span><br />
        <span className="cookie-description">{props.description}</span>
    </div>
)

export default CookieFake