import React from "react"

import { Collapse } from 'react-bootstrap'

//Damit FontAwesome Icon nicht in riesiger Größe flashed
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

config.autoAddCss = false;

const Toggler = (props) => {

    const [open, setOpen] = React.useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(!open)}
                aria-controls={"collapse-text-" + props.uniqueId}
                aria-expanded={open}
                className="job-collapse-trigger"
                onKeyPress={() => setOpen(!open)}
            >
                <h2>
                    {props.title} {!open ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
                </h2>
            </button>
            <Collapse in={open}>
                <div id={"collapse-text-" + props.uniqueId} className="jobs-collapse-text">
                    {props.children}
                </div>
            </Collapse>
        </>
    );
}

export default Toggler