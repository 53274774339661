import React, { Component } from 'react'
import {Cookies} from 'react-cookie'

const cookies = new Cookies();

class Cookie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: cookies.get(this.props.activationCookie)  === 'true',
        };
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    setCheckedTrue = () => {
        this.setState({
            isChecked: true,
        });
    }

    setCheckedFalse = () => {
        this.setState({
            isChecked: false
        });
    }

    handleCookies()
    {
        //Aktivierungs-Cookie auf 1 setzen
        if (this.state.isChecked) {
            cookies.set(this.props.activationCookie, 'true', {'path': '/', 'domain': this.props.domain, 'maxAge': 8640000});
        }
        //Alle Cookies löschen, die mit jeweiligem Cookie zusammen hängen
        else {
            let currentCookie = this;
            this.props.onRemoveCookies.forEach(function(cookie) {
                cookies.remove(cookie, {'path': '/', 'domain': currentCookie.props.domain});
            });
            cookies.remove(this.props.activationCookie, {'path': '/', 'domain': currentCookie.props.domain});
        }
    }
    addActivationCookie()
    {
        cookies.set(this.props.activationCookie, 'true', {'path': '/', 'domain': this.props.domain, 'maxAge': 8640000});
    }
    removeAllCookies()
    {
        let currentCookie = this;
        this.props.onRemoveCookies.forEach(function(cookie) {
            cookies.remove(cookie, {'path': '/', 'domain': currentCookie.props.domain});
        });
        cookies.remove(this.props.activationCookie, {'path': '/', 'domain': currentCookie.props.domain});
    }

    render() {
        return (
            <div className="cookie-item">
                <label className="cookie-bar-switch">
                    <input type="checkbox"
                           checked={this.state.isChecked}
                           onChange={this.toggleChange}
                           className="cookie-bar-input"
                    />
                    <span className="cookie-bar-slider"> </span>
                </label>
                <span className="cookie-name">{this.props.name}</span><br />
                <span className="cookie-description">{this.props.description}</span>
            </div>


        );
    }
}

export default Cookie